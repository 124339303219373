const enterprise: any = {
  title: {
    fr: "Découvrez nos solutions pour Entreprises",
    ar: "اكتشف حلولنا الخاصة بالشركات",
    en: "Discover our solutions for businesses",
  },
  subtitle: {
    fr: (
      <>
        Des solutions innovantes et connectées à vos Popcards pour vous aider à
        piloter votre activité et augmenter votre chiffre d'affaires.
        <br />
        Choisissez la solution qui vous convient !
      </>
    ),
    ar: (
      <>
        حلول مبتكرة ومتصلة ببطاقات Popcard الخاصة بك لمساعدتك في إدارة نشاطك
        وزيادة إيراداتك.
        <br />
        اختر الحل الذي يناسبك!
      </>
    ),
    en: (
      <>
        Innovative and connected solutions with your Popcards to help you manage
        your business and increase your revenue.
        <br />
        Choose the solution that suits you!
      </>
    ),
  },
};

export default enterprise;
