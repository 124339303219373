import React from "react";
import { cn } from "@/lib/cn";
import { TransitionLink } from "./TransitionLink";

interface buttonLinkProps {
  href: string;
  title: string;
  className?: string;
}
const buttonLink = ({ href, title, className = "" }: buttonLinkProps) => {
  return (
    <TransitionLink
      href={href}
      className={cn(
        "text-sm sm:text-md font-semibold  bg-white text-neutral-900 px-6 py-2 rounded-full hover:bg-neutral-200",
        className
      )}
    >
      {title}
    </TransitionLink>
  );
};

export default buttonLink;
