import { cn } from "@/lib/cn";

const Container = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={cn("max-w-[1250px] mx-auto px-8 ", className)}>
      {children}
    </div>
  );
};

export default Container;
