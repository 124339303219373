"use client";
import React, { useEffect, useRef } from "react";
import Image from "next/image";
import checkIcon from "@/assets/checkedIcon.png";
import unChekedIcon from "@/assets/unChekedIcon.svg";
import ButtonLink from "./button-link";
import { cn } from "@/lib/cn";
import { motion } from "framer-motion";
import productsTranslation from "@/translation/home/productsTranslation";

const variant = {
  initial: {
    opacity: 0,
    y: -100,
  },
  animate: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2 * index,
    },
  }),
};
const Product = ({
  product,
  index,
  lang,
}: {
  index: number;
  lang: string;
  product: {
    name: string;
    detail?: string;
    image: any;
    oldPrice?: number;
    price: number;
    link: string;
    checked: { fr: string; ar: string; en: string }[];
    uncheked: { fr: string; ar: string; en: string }[];
    gradient: string;
    withBlur?: boolean;
    title?: string;
    isFlipping?: boolean;
    backCard?: any;
    buttonText?: string | { fr: string; ar: string; en: string };
  };
}) => {
  return (
    <motion.div
      className="rounded-3xl pb-4 border flex flex-col  gap-4  border-neutral-500 bg-neutral-900  border-white/10 bg-gradient-to-br from-black/5 to-black/50 max-w-[400px] overflow-hidden shadow-sm shadow-white/5"
      variants={variant}
      initial="initial"
      whileInView="animate"
      custom={index}
      viewport={{ once: true }}
    >
      <div
        className={cn(
          "p-3 rounded-tr-3xl rounded-tl-3xl relative",
          !product.withBlur ? product.gradient : ""
        )}
      >
        {product.title && (
          <div className="text-center text-lg text-black font-medium w-full mt-2 relative z-10">
            {productsTranslation[product.title][lang]}
          </div>
        )}
        {product.withBlur && (
          <div
            className={cn(
              "w-full h-full top-0 left-0 absolute z-0",
              product.gradient
            )}
          ></div>
        )}
        {product.isFlipping && (
          <div className="w-full h-full relative flipped">
            <Image
              src={product.image}
              alt="product's image"
              className="front w-full h-full object-cover relative z-100  top-0 left-0 backface-hidden transition-all duration-500 perspective-400"
            />
            <Image
              src={product.backCard}
              alt="product's image"
              className="back w-full h-full object-cover absolute z-100  top-0 left-0 backface-hidden transition-all duration-500 perspective-400"
            />
          </div>
        )}

        {!product.isFlipping && (
          <Image
            src={product.image}
            alt="product's image"
            className="front w-full full object-contain relative mx-auto rounded-xl"
          />
        )}
      </div>

      <div className="flex items-start justify-between gap-8 mt-4 px-3 min-h-12 overflow-hidden">
        {!product.detail && (
          <>
            <h3 className="font-semibold text-lg sm:text-xl capitalize">
              {productsTranslation[product.name][lang]}
            </h3>
            <span className="flex relative text-lg sm:text-xl font-medium min-w-24">
              <span className="text-left">
                {product.price} {productsTranslation.mad[lang]}
              </span>
              {product.oldPrice && (
                <span className="absolute -bottom-6  h-full text-center text-nowrap text-md sm:text-lg line-through">
                  {product.oldPrice} {productsTranslation.mad[lang]}
                </span>
              )}
            </span>
          </>
        )}

        {product.detail && (
          <>
            <div className="text-center w-full px-4">
              {productsTranslation[product.detail][lang]}
            </div>
          </>
        )}
      </div>

      <ul className="mt-4 flex flex-col gap-4 px-4 grow">
        {product.checked.map((check: any) => {
          return (
            <li
              className="text-md sm:text-md  text-light-gray flex items-baseline gap-3"
              key={check + index}
            >
              <Image src={checkIcon} alt={""} className="block -mb-[2px]" />
              <span>{check[lang]}</span>
            </li>
          );
        })}

        {product.uncheked.map((check: any) => {
          return (
            <li
              className="text-md sm:text-md text-light-gray flex items-baseline gap-3"
              key={index + check}
            >
              <Image src={unChekedIcon} alt={""} />
              <span>{check[lang]}</span>
            </li>
          );
        })}
      </ul>

      <ButtonLink
        href={product.link}
        title={
          product.buttonText
            ? lang === "fr"
              ? "En savoir plus"
              : lang === "ar"
              ? "معرفة المزيد"
              : "Learn more" // for English
            : lang === "fr"
            ? "Commander"
            : lang === "ar"
            ? "اطلب الان"
            : "Order now" // for English
        }
        className="w-fit self-center mt-4"
      />
    </motion.div>
  );
};

export default Product;
