"use client";
import { cn } from "@/lib/cn";
import React from "react";
import { motion } from "framer-motion";
interface SectionsHeaderProps {
  heading: string | React.ReactNode;
  subHeading?: string | React.ReactNode;
  className?: string;
}
const SectionsHeader = ({
  heading,
  subHeading,
  className,
}: SectionsHeaderProps) => {
  return (
    <motion.div
      className={cn("flex flex-col items-center gap-4", className)}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.15,
        },
      }}
      viewport={{ once: true }}
    >
      <h2 className="text-2xl sm:text-4xl  text-white font-semibold text-center !leading-[150%] mb-4">
        {heading}
      </h2>
      {subHeading && (
        <p className="text-light-gray textls sm:text-md text-center leading-[150%] max-w-[650px]">
          {subHeading}
        </p>
      )}
    </motion.div>
  );
};

export default SectionsHeader;
