import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { cn } from "@/lib/cn";
import Image from "next/image";
import Client1Src from "@/assets/clients/client-1.png";
import Client2Src from "@/assets/clients/client-2.png";
import Client3Src from "@/assets/clients/client-3.png";
import Client4Src from "@/assets/clients/client-4.png";
import Client5Src from "@/assets/clients/client-5.png";
import { FaPlay } from "react-icons/fa6";
import { motion } from "framer-motion";

const CLIENTS = [
  {
    id: 1,
    src: Client1Src,
    href: "https://www.instagram.com/reel/Cx8zzferOOP/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
  },
  {
    id: 2,
    src: Client2Src,
    href: "https://www.instagram.com/reel/CyWNl52tumc/?igsh=MzRwbG1wY3ZhNXBh",
  },
  {
    id: 3,
    src: Client3Src,
    href: "https://www.instagram.com/reel/CyOreNPtdbB/?igsh=dXJ4Z3VuYW5pNjBh",
  },
  {
    id: 4,
    src: Client4Src,
    href: "https://www.instagram.com/reel/CxqqTPHLlHu/?igsh=MTRmaW51cTMyeG1weg==",
  },
  {
    id: 5,
    src: Client5Src,
    href: "https://www.instagram.com/reel/CxyX8PtL7No/?igsh=MWlxNHl2Y2E1a3Bmeg%3D%3D",
  },
];

export default function FloatingSlider({ className }: { className: string }) {
  const [ref] = useKeenSlider<HTMLDivElement>({
    breakpoints: {
      "(min-width: 100px)": {
        slides: { perView: 1, spacing: 10 },
      },
      "(min-width: 400px)": {
        slides: { perView: 2, spacing: 10 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 10 },
      },
    },
  });
  return (
    <motion.div
      ref={ref}
      className={cn("keen-slider rounded-xl overflow-hidden", className)}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.15,
        },
      }}
      viewport={{ once: true }}
    >
      {CLIENTS.map((client, index) => {
        return (
          <div key={index} className="keen-slider__slide relative bg-red-500">
            <Image
              src={client.src}
              className="w-full h-full object-cover"
              alt="client image"
            />
            <a
              target="_blank"
              href={client.href}
              className="absolute w-full h-full bg-neutral-950/30 top-0 left-0 grid place-items-center hover:bg-neutral-950/10 transition-colors"
              aria-label={"client " + index}
            >
              <FaPlay size={40} />
            </a>
          </div>
        );
      })}
    </motion.div>
  );
}
