const products: any = {
  title: {
    fr: (
      <>
        ... L’essentiel est de toujours garder sa Popcard.
        <br />
        Commandez la votre maintenant !
      </>
    ),
    ar: (
      <>
        ... المهم هو دائمًا الاحتفاظ بـ Popcard.
        <br />
        اطلبها الآن!
      </>
    ),
    en: (
      <>
        ... The key is to always keep your Popcard.
        <br />
        Order yours now!
      </>
    ),
  },
  subtitle: {
    fr: <>Une variété de choix pour tous vos besoins.</>,
    ar: <>مجموعة متنوعة من الخيارات لتلبية جميع احتياجاتك.</>,
    en: <>A variety of options for all your needs.</>,
  },

  popcardStandard: {
    fr: "Popcard Pro Standard",
    ar: "Popcard Pro Standard",
    en: "Popcard Pro Standard",
  },
  popcardPersonalise: {
    fr: "Popcard Pro Personnalisée",
    ar: "Popcard Pro Personnalisée",
    en: "Popcard Pro Customized",
  },
  popcardReviews: {
    fr: "Popcard Reviews",
    ar: "Popcard Reviews",
    en: "Popcard Reviews",
  },
  popcardMetrics: {
    fr: "Popcard Metrics",
    ar: "Popcard Metrics",
    en: "Popcard Metrics",
  },
  coffretEssential: {
    fr: "Coffret Essentiel",
    ar: "Coffret Essentiel",
    en: "Essential Box",
  },
  coffretEssentialPlus: {
    fr: "Coffret Essentiel Plus",
    ar: "Coffret Essentiel Plus",
    en: "Essential Plus Box",
  },
  coffretPremium: {
    fr: "Coffret Premium",
    ar: "Coffret Premium",
    en: "Premium Box",
  },
  popcardRewards: {
    fr: "Popcard Rewards",
    ar: "Popcard Rewards",
    en: "Popcard Rewards",
  },
  rewardsDetail: {
    ar: "واجهة إدارة لتشغيل برنامج الولاء الخاص بك",
    fr: "Un backoffice pour piloter votre programme de fidélité",
    en: "A back office to manage your loyalty program",
  },
  metricsDetail: {
    fr: "Une plateforme de gestion connectée à l'ensemble de vos cartes de visite Popcard",
    ar: "منصة إدارة متصلة بجميع بطاقات زيارة Popcard الخاصة بك",
    en: "A management platform connected to all your Popcard business cards",
  },
  mad: {
    fr: "MAD",
    ar: "درهم",
    en: "MAD",
  },
};

export default products;
