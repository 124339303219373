"use client";
import React, { useContext } from "react";
import SectionsHeader from "../ui/sections-header";
import FloatingSlider from "../ui/floating-slider";
import { cn } from "@/lib/cn";
import Container from "../ui/Container";
import { languageContext } from "@/contexts/language-context";
import clientsTranslation from "@/translation/home/clientsTranslation";

const Clients = ({ className }: { className?: string }) => {
  const { lang } = useContext<any>(languageContext);
  return (
    <div className={cn("w-full py-[5rem]", className)}>
      <Container className="">
        <SectionsHeader
          heading={clientsTranslation.title[lang]}
          subHeading={clientsTranslation.subtitle[lang]}
        />
        <FloatingSlider className="mt-8" />
      </Container>
    </div>
  );
};

export default Clients;
