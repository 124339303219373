const clients: any = {
  title: {
    fr: "Ce que nos clients pensent de nous",
    ar: "ما يقوله عملاؤنا عنا",
    en: "What our clients think of us",
  },
  subtitle: {
    fr: "Spoilers, c’est plutôt bien !",
    ar: "تنبيه، هذا جيد جداً!",
    en: "Spoiler: it’s pretty good!",
  },
};

export default clients;
