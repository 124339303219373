"use client";
import React, { useContext } from "react";
import Product from "../ui/product";
import SectionsHeader from "../ui/sections-header";
import Container from "../ui/Container";
import { cn } from "@/lib/cn";

import backCard from "@/assets/products/backCard.svg";
import StandardSrc from "@/assets/products/standard.png";
import PersonaliseSrc from "@/assets/products/personalise.png";
import ReviewsSrc from "@/assets/products/reviews.svg";
import backReviews from "@/assets/products/backReviews.png";
import { languageContext } from "@/contexts/language-context";
import productsTranslation from "@/translation/home/productsTranslation";

const products = [
  {
    name: "popcardStandard",
    image: StandardSrc,
    backCard: backCard,
    isFlipping: true,
    gradient: "bg-gradient-to-r from-gray-100 to-slate-300",
    withBlur: false,
    price: 290,
    oldPrice: 390,
    checked: [
      {
        fr: "Partage & Collecte illimitée",
        ar: "مشاركة وتجميع غير محدود",
        en: "Unlimited Sharing & Collecting",
      },
      {
        fr: "Modifiez vos informations à tout moment",
        ar: "تعديل معلوماتك في أي وقت",
        en: "Edit your information anytime",
      },
      {
        fr: "3 couleurs d’impressions",
        ar: "3 ألوان للطباعة",
        en: "3 printing colors",
      },
      {
        fr: "Personnalisation Partielle",
        ar: "تخصصيص جزئي",
        en: "Partial customization",
      },
      {
        fr: "Profil multilingue",
        ar: "ملف تعريف بلغات متعددة",
        en: "Multilingual profile",
      },
    ],
    uncheked: [],
    link: "/commande-popcard-pro-standard",
  },
  {
    name: "popcardPersonalise",
    image: PersonaliseSrc,
    backCard: backCard,
    isFlipping: true,
    gradient: "bg-gradient-to-r from-gray-100 to-slate-300",
    withBlur: false,
    price: 390,
    oldPrice: 490,
    checked: [
      {
        fr: "Partage & Collecte illimitée",
        ar: "مشاركة وتجميع غير محدود",
        en: "Unlimited Sharing & Collecting",
      },
      {
        fr: "Modifiez vos informations à tout moment",
        ar: "تعديل معلوماتك في أي وقت",
        en: "Edit your information anytime",
      },
      {
        fr: "3 couleurs d’impressions",
        ar: "3 ألوان للطباعة",
        en: "3 printing colors",
      },
      {
        fr: "Personnalisation complète",
        ar: "تخصيص شامل",
        en: "Complete customization",
      },
      {
        fr: "Profil multilingue",
        ar: "ملف تعريف بلغات متعددة",
        en: "Multilingual profile",
      },
    ],
    uncheked: [],
    link: "/commande-popcard-pro-personalise",
  },
  {
    name: "popcardReviews",
    backCard: ReviewsSrc,
    image: backReviews,
    isFlipping: true,
    gradient: "bg-gradient-to-r from-gray-100 to-slate-300",
    withBlur: false,
    price: 190,
    oldPrice: 290,
    checked: [
      {
        fr: "Partage illimité",
        ar: "مشاركة غير محدودة",
        en: "Unlimited sharing",
      },
      {
        fr: "Comptabilité avec toutes les plateformes d'avis",
        ar: "التوافق مع جميع منصات التعليقات",
        en: "Compatible with all review platforms",
      },
      {
        fr: "3 couleurs d’impressions",
        ar: "3 ألوان للطباعة",
        en: "3 printing colors",
      },
      {
        fr: "Personnalisation complète",
        ar: "تخصيص شامل",
        en: "Complete customization",
      },
      {
        fr: "Collecte instantanée des avis",
        ar: "جمع فوري للتعليقات",
        en: "Instant review collection",
      },
    ],
    uncheked: [],
    link: "/commande-popcard-reviews",
  },
];

const Products = ({
  showHeader = true,
  className,
}: {
  showHeader: boolean;
  className?: string;
}) => {
  const { lang } = useContext<any>(languageContext);
  return (
    <div className={cn("w-full relative py-[3rem] sm:py-[5rem]", className)}>
      <Container className={""}>
        {showHeader && (
          <SectionsHeader
            heading={productsTranslation.title[lang]}
            subHeading={productsTranslation.subtitle[lang]}
          />
        )}
      </Container>
      <Container className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 justify-items-center mt-8  px-2 sm:px-4">
        {products.map((product, index) => {
          return (
            <Product
              key={product.name}
              product={product}
              index={index + 1}
              lang={lang}
            />
          );
        })}
      </Container>
    </div>
  );
};

export default Products;
