"use client";
import React, { useContext } from "react";
import SectionsHeader from "../ui/sections-header";
import Product from "../ui/product";
import Container from "../ui/Container";
import { cn } from "@/lib/cn";

import MetricsSrc from "@/assets/products/metrics.png";
import RewardsSrc from "@/assets/products/rewards.png";
import { languageContext } from "@/contexts/language-context";
import enterprise from "@/translation/home/enterpriseTranslation";

const products = [
  {
    title: "popcardMetrics",
    name: "product 1",
    detail: "metricsDetail",
    image: MetricsSrc,
    gradient: "bg-yellow-500 blur-[10px]",
    withBlur: true,
    price: 390,
    oldPrice: 490,
    checked: [
      {
        fr: "Cartes personnalisées liées à un backoffice",
        ar: "بطاقات مخصصة مرتبطة بنظام خلفي",
        en: "Customized cards linked to a back office",
      },
      {
        fr: "Gestion des profils collaborateurs",
        ar: "إدارة ملفات تعريف الموظفين",
        en: "Employee profile management",
      },
      {
        fr: "Statistiques détaillées en Temps Réel",
        ar: "إحصائيات مفصلة في الوقت الفعلي",
        en: "Detailed real-time statistics",
      },
      {
        fr: "Collecte de leads",
        ar: "جمع العملاء المحتملين",
        en: "Lead collection",
      },
    ],
    uncheked: [],
    link: "/commande-popcard-entreprise",
    buttonText: {
      fr: "En savoir plus",
      ar: "معرفة المزيد",
      en: "Learn more",
    },
  },
  {
    title: "popcardRewards",
    name: "product 2",
    detail: "rewardsDetail",
    image: RewardsSrc,
    gradient: "bg-gradient-to-tr from-blue-600 to-red-500 blur-[10px]",
    withBlur: true,
    price: 390,
    oldPrice: 490,
    checked: [
      {
        fr: "Accès non plafonné à l'application Staff",
        ar: "وصول غير محدود لتطبيق الموظفين",
        en: "Unlimited access to the Staff app",
      },
      {
        fr: "Cartes de fidélité virtuelles et illimitées pour vos clients",
        ar: "بطاقات ولاء افتراضية وغير محدودة لعملائك",
        en: "Unlimited virtual loyalty cards for your customers",
      },
      {
        fr: "Support Marketing à la demande",
        ar: "دعم تسويقي حسب الطلب",
        en: "On-demand marketing support",
      },
    ],
    uncheked: [],
    link: "/rewards/commercants",
    buttonText: {
      fr: "En savoir plus",
      ar: "معرفة المزيد",
      en: "Learn more",
    },
  },
];

const Enterprise = ({
  showHeader = true,
  className,
}: {
  showHeader: boolean;
  className?: string;
}) => {
  const { lang } = useContext<any>(languageContext);
  return (
    <div className={cn("w-full py-[5rem]", className)}>
      <Container className="mx-auto px-0">
        {showHeader && (
          <SectionsHeader
            heading={enterprise.title[lang]}
            subHeading={enterprise.subtitle[lang]}
          />
        )}
        <div className="flex flex-col items-center sm:items-stretch sm:flex-row justify-center gap-12  mt-12 px-2 sm:px-4 mx-0">
          {products.map((product, index) => {
            return (
              <Product
                product={product}
                key={product.name}
                index={index + 1}
                lang={lang}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Enterprise;
