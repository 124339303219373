"use client";
import { createContext, useEffect, useState } from "react";
import { Noto_Kufi_Arabic, Poppins } from "next/font/google";

const fontFr = Poppins({
  weight: ["100", "200", "300", "400", "500", "600", "700"],
  subsets: ["latin"],
});

const fontAr = Noto_Kufi_Arabic({
  weight: ["100", "200", "300", "400", "500", "600", "700"],
  subsets: ["latin"],
});
type langType = {
  lang: string;
  setLang: (lang: string) => void;
};

export const languageContext = createContext<langType | null>(null);

export const LanguageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [lang, setLang] = useState("fr");

  useEffect(() => {
    window.localStorage.setItem("lang", lang);
    const root = document.getElementsByTagName("html")[0];
    if (lang === "fr" || lang == "en") {
      root.setAttribute("class", fontFr.className);
      root.setAttribute("dir", "ltr");
      root.setAttribute("lang", lang);
    } else {
      root.setAttribute("class", fontAr.className);
      root.setAttribute("dir", "rtl");
      root.setAttribute("lang", lang);
    }
  }, [lang]);

  return (
    <languageContext.Provider value={{ lang, setLang }}>
      {children}
    </languageContext.Provider>
  );
};
